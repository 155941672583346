@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

// CopingCard styles
// colors
@--white: white;
@--dark-gray: #45403e;
@--light-gray: #f5f1f1;
@--border-gray: #acbbb9;
@--border-shadow: #8d919133;
@--primary-0: #b21f00;
@--primary-1: #ee5c3e;
@--primary-2: #d9320e;
@--primary-3: #8f1900;
@--primary-4: #641100;
@--triad-blue-0: #055270;
@--triad-blue-1: #2b7895;
@--triad-blue-2: #0e6788;
@--triad-blue-3: #03425a;
@--triad-blue-4: #012e3f;
@--triad-green-0: #5ca200;
@--triad-green-1: #93d939;
@--triad-green-2: #76c60d;
@--triad-green-3: #4a8300;
@--triad-green-4: #345b00;
@--complement-0: #008130;
@--complement-1: #2dad5c;
@--complement-2: #0a9d41;
@--complement-3: #006827;
@--complement-4: #00491b;
@--adjacent-0: #B25D00;
@--adjacent-1: #D9780F;
@--adjacent-2: #EE9A3E;
@--adjacent-3: #8F4C00;
@--adjacent-4: #643400;


// font
@font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// base variables
@border-radius-base: 6px;

// colors
@primary-color: @--triad-blue-2;
@error-color: @--primary-2;
@text-color: @--triad-blue-4;

// sider
@layout-sider-background: @--triad-blue-4;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #2a132e;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-select-item-group {
  font-size: 14px;
  font-weight: 600;
  color: @--triad-blue-4; 
}
