.ant-timeline.ant-timeline-label .timeline-label .ant-timeline-item-label {
  width: 90px;
}


.ant-timeline.ant-timeline-label .timeline-label .ant-timeline-item-tail {
  left: 100px;
}

.ant-timeline.ant-timeline-label .timeline-label .ant-timeline-item-head {
  left: 100px;
}

.ant-timeline.ant-timeline-label .timeline-label.ant-timeline-item-left .ant-timeline-item-content {
  left: 110px;
  width: auto;
}

.calendard-event-badge .ant-badge-status-text {
  font-size: 12px;
}

.calendar-title {
  margin: 0 25px 0 0;
}

.calendar-title-container {
  display: flex;
  flex-direction: column;
} 


.calendar-title-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
} 

.calendar-title--stats {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 0 auto;

  @media screen and (max-width: 600px) {
    margin: 10px;
  }
}

.calendar-title-days {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;

  @media screen and (max-width: 600px) {
    margin: 10px;
  }

}

.calendar-title--log-button {
  margin: 10px 40px 10px 30px;
}

.calendar-title--alert {
  margin: 0 5px 0 30px;
}

.drawer-header--extra {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-activity--duration {
  margin: 10px;
}
