
.worry-tree-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.worry-tree--client-selector {
  width: 250px;
}

.worry-tree--input {
  width: 80%;
  margin-bottom: 20px;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
    
  @media screen and (max-width: 800px) {
    width: 100%;
  }
} 

.fading-container {
  animation: fadeIn ease 2s;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.worry-tree--buttons-container {
  display: flex;
  max-width: 250px;
  width: 200px;
  justify-content: space-between;
}

.worry-tree--image-title {
  margin: 20px 0;
  }

