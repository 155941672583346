@import '../../styles/antd.less';

.title {
 color: @--triad-blue-0 !important;
}

.price-card .ant-card-actions {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 55px;
}

.price-card .ant-list-sm .ant-list-item {
  padding: 4px 8px;
}

.card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.support-dropdown-ru .ant-select-item .ant-select-item-option-content {
  font-size: 11px;
}

.selected-plan-checkbox {
  margin-right: 10px;
  font-size: 30px;
  color: @--triad-blue-0 !important;
}

.unselected-plan-checkbox {
  margin-right: 10px;
  font-size: 30px;
  color: @--triad-blue-1 !important;
}

.clients-number {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clients-number-input {
  margin-left: auto;
}

.action-row {
  color: @--triad-blue-0;
  font-size: 16px;
  font-weight: 600;
}


.legal-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
