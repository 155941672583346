
.behavior-experiment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.behavior-experiment--client-selector {
  width: 250px;
}

.behavior-experiment--input {
  width: 80%;
  margin-bottom: 20px;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
    
  @media screen and (max-width: 800px) {
    width: 100%;
  }
} 

.fading-container {
  animation: fadeIn ease 2s;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.behavior-experiment--buttons-container {
  display: flex;
  max-width: 250px;
  width: 200px;
  justify-content: space-between;
}

.behavior-experiment--image-title {
  margin: 20px 0;
}

.behavior-experiment--title {
  margin-bottom: 20px !important;
  text-align: center;

  @media screen and (max-width: 575px) {
   text-align: left;
  }
}

.behavior-experiment--tooltip {
  min-width: 50%;

  @media screen and (max-width: 575px) {
    min-width: unset;
  }
}

.behavior-experiments--thoughts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.behavior-experiments--thoughts-container {
  margin: 25px;
}

.behavior-experiment--question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
} 


.behavior-experiments--question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.behavior-experiments--arrow-icon {
  margin-top: 20px;
} 
