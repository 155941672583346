
.exposure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.exposure--client-selector {
  width: 250px;
}

.exposure--input {
  width: 80%;
  margin-bottom: 20px;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
    
  @media screen and (max-width: 800px) {
    width: 100%;
  }
} 

.fading-container {
  animation: fadeIn ease 2s;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.exposure--buttons-container {
  display: flex;
  max-width: 250px;
  width: 200px;
  justify-content: space-between;
}

.exposure--image-title {
  margin: 20px 0;
}

.exposure--title {
  margin-bottom: 20px !important;
  text-align: center;

  @media screen and (max-width: 575px) {
   text-align: left;
  }
}

.exposure--tooltip {
  min-width: 50%;

  @media screen and (max-width: 575px) {
    min-width: unset;
  }
}

.exposures--thoughts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exposures--thoughts-container {
  margin: 25px;
}

.exposure--question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
} 


.exposures--question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.exposures--arrow-icon {
  margin-top: 20px;
} 

.exposures--generate-row {
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
} 


.exposures--situation-row {
  display: flex;
  flex-flow: wrap;
  flex-wrap: wrap;
}
.exposures--move-buttons {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
} 

.exposures--move-row {
  display: flex;
  align-items: center;
}

.exposures--is-generating-row {
  display: flex;
  justify-content: center;
}

.exposure--back-row {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.exposure--generate-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.exposure--generate-row--or {
  margin: 5px 0 5px 80px;
}

.exposure--stopwatch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  flex-wrap: wrap;
}

.exposure--sud-row {
  display: flex;
  align-items: baseline;
  font-weight: 600;

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }

}

