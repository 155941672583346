
.rowContainer {
  display: flex;
  flex-direction: row;
  margin: 30px 0;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}


.startFromTemplateSelect {
  width: 450px !important;

  @media screen and (max-width: 1270px) {
    width: 400px  !important;
  }

  @media screen and (max-width: 1140px) {
    width: 350px  !important;
  }
}

.thoughtRow {
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.thoughtRowInput {
  width: 400px;

  @media screen and (max-width: 470px) {
    width: 350px;
  }

  @media screen and (max-width: 420px) {
    width: 300px;
  }
}

.thoughtWithMinus {
  display: flex;
  align-items: baseline;
}
