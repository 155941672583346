@import '../../styles/antd.less';

.row-container--card {
  display: flex;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.card-form-item {
  flex: 1;
  flex-basis: 300px;

  @media screen and (max-width: 1100px) {
    flex: 0;
    flex-basis: 'auto';
  }
}


.rowContainer {
  display: flex;
  flex-direction: row;
  margin: 30px 0;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.rowContainerSelect {
  margin: 30px 0;
}

.rowContainerSelect.ant-form-item {
  margin-bottom: 0;
}

.select-client-item {
 width: 375px;

  @media screen and (max-width: 500px) {
    width: 225px;
  }
}
