
.session-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.session-client-selector {
  width: 250px;
}

.session--input {
  width: 80%;
  margin-bottom: 20px;

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
    
  @media screen and (max-width: 800px) {
    width: 100%;
  }
} 

.session--buttons-container {
  display: flex;
  max-width: 250px;
  width: 200px;
  justify-content: space-between;
}

.session--image-title {
  margin: 20px 0;
}

.session--title {
  margin-bottom: 20px !important;
  text-align: center;

  @media screen and (max-width: 575px) {
   text-align: left;
  }
}

.session--tooltip {
  min-width: 50%;

  @media screen and (max-width: 575px) {
    min-width: unset;
  }
}


.ant-cascader-menu-item-content {
  white-space: wrap;
}


.ant-tag.diagnosis--tag {
  white-space: wrap;
  padding: 5px;
}


.sessions--mental-status {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.sessions--mental-status__s {
  width: 200px;
  max-width: 200px;
  margin-right: 20px;
}


.sessions--data-column--s {
  width: 200px;
  max-width: 200px;
  margin-right: 20px;
}

.sessions--data-column--m {
  width: 250px;
  max-width: 250px;
  margin-right: 20px;
}

.sessions--data-column--l {
  width: 290px;
  max-width: 290px;
  margin-right: 20px;
}

.sessions--data-column--xl {
  width: 340px;
  max-width: 340px;
  margin-right: 20px;
}

.sessions--data-column--xxl {
  width: 370px;
  max-width: 370px;
  margin-right: 20px;
}

.sessions--data-column--xxxl {
  width: 400px;
  max-width: 400px;
  margin-right: 20px;

  @media screen and (max-width: 450px) {
    width: 100%;
    max-width: 100%;
  }
}

.sessions--data-column--xxxxl {
  width: 450px;
  max-width: 450px;
  margin-right: 20px;

  @media screen and (max-width: 450px) {
    width: 100%;
    max-width: 100%;
  }
}



.sessions--data-column--max {
  width: 100%;
  max-width: 100%;
  margin-right: 20px;
}


.ql-editor ul[data-checked="true"] li {
  text-decoration: line-through;
}

.ql-editor ul[data-checked="false"] > li::before {
    content: '\2E3A';
    font-size: 1.5rem;
}

.ql-editor ul[data-checked="true"] li::before {
    content: '\2713';
    font-size: 1.5rem;
}

.sessions--vertical-column--s  {
  display: flex;
  flex-direction: column;
  width: 200px;
  max-width: 200px;
  margin-right: 20px;
  align-items: baseline;
}

.sessions--vertical-column--m  {
  display: flex;
  flex-direction: column;
  width: 250px;
  max-width: 250px;
  margin-right: 20px;
  align-items: baseline;
}

.note-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.save-pdf--button {
  margin-left: auto;
  margin-right: 20px;

  @media screen and (max-width: 640px) {
    margin: 10px 0 10px 0;
  }
}

.spin-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.duration--content {
  margin: 0 10px;
}

.ant-form-item.sessions--data-column--max .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector{
  height: auto;
}
.ant-select-selector .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}
