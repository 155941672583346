.ant-layout-header.header {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: auto;
}

.header-filter-client {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.header-filter-client--label {
  margin-right: 10px;
  font-weight: 600;
}

.header-filter-client--tooltip {
  margin: 0 20px 0 10px;
}


.header .header-main-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: auto;

  @media screen and (max-width: 1040px) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
}

.header-divider {
  margin: 0 0 10px 0;
}


.header-session-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.ant-form-item.header-session-row--item {
  margin-right: 10px;
  margin-bottom: 0;
}


.header-session-button {
  @media screen and (max-width: 472px) {
    margin: 10px 0 0 0;
    
  }
}
