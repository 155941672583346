

.profile-upload-card .ant-upload-list-picture-card-container {
  width: 208px;
  height: 208px;
}


.profile-upload-card .ant-upload-select-picture-card {
  width: 208px;
  height: 208px;
}


.profile-upload-card__upload-icon {
  margin-right: 8px;
}
